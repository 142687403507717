import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";

function Main() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="main1_box">
        <div className="main1_box_inner">
          <h2>KM RAINBOOTS</h2>
          <h1>
            We are a shoe store from Kunming specializing in men’s rain boots
          </h1>
          <div style={{ textAlign: "center" }}>
            <Link class="main1_to_shop" to="/shop">
              <span class="fusion-button-text">SHOP NOW</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="main2_box">
        <h2>All OF OUR PRODUCTS</h2>
        <div className="main2_item_box">
          <ul>
            {productData.map((shoe, index) => (
              <li key={index}>
                <Link to={`/shopDetails/${shoe.id}`} onClick={toTop}>
                  <div className="main2_item_img">
                    <img alt="" src={shoe.images[0]} />
                  </div>
                  <div className="main2_item_txt">
                    <h3>
                      <Link to={`/shopDetails/${shoe.id}`} onClick={toTop}>
                        {shoe.name}
                      </Link>
                    </h3>
                    <div className="main2_item_price">
                      <bdi>
                        <span class="woocommerce-Price-currencySymbol">$</span>
                        {shoe.price}
                      </bdi>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="main3_box">
        <div className="main3_box_inner">
          <h2>Subscribe To Our Newsletter</h2>
          <h3>TO GET THE UPDATE WEEKLY</h3>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
