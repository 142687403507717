import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="foot_inner">
          <div className="foot_left">
            <img
              alt=""
              src="https://yaotradinginc.com/wp-content/uploads/2024/08/%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20240809180025.png"
            />
          </div>

          <div className="foot_mid">
            <h2>USEFUL LINKS</h2>
            <ul>
              <li>
                <Link to="/" onClick={toTop}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/shop" onClick={toTop}>
                  Shop
                </Link>
              </li>
              <li>
                <Link to="/cartpage" onClick={toTop}>
                  Cart
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={toTop}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div className="foot_bot">
            <h2>CONTACT INFO</h2>
            <p>KUN MING SHU CHEN WANG LUO KE JI CO., LTD</p>
            <p>
              Room K-916, 9th Floor, Building 1, Wancaicheng, No. 127, Longquan
              Road, Lianhua Street, Wuhua District, Kunming City, Yunnan
              Province, China
            </p>
            <p>Phone/Whatsapp: (415) 425-9345</p>
            <p>Email: work@woksi.cfd</p>
          </div>
          <div className="foot_bottom_txt">
            <p>
              © Copyright 2024 | yaotradinginc.com | KUN MING SHU CHEN WANG LUO
              KE JI CO., LTD
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
