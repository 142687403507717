import React from "react";
import productData from "../Datas/productData";
import Header from "./Header";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const Shop = () => {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div
          className="detail_top_box"
          style={{
            background: "#333333",
          }}
        ></div>

        <div className="main2_box" style={{ marginTop: "4%" }}>
          <div className="main2_item_box">
            <ul>
              {productData.map((shoe, index) => (
                <li key={index}>
                  <Link to={`/shopDetails/${shoe.id}`} onClick={toTop}>
                    <div className="main2_item_img">
                      <img alt="" src={shoe.images[0]} />
                    </div>
                    <div className="main2_item_txt">
                      <h3>
                        <Link to={`/shopDetails/${shoe.id}`} onClick={toTop}>
                          {shoe.name}
                        </Link>
                      </h3>
                      <div className="main2_item_price">
                        <bdi>
                          <span class="woocommerce-Price-currencySymbol">
                            $
                          </span>
                          {shoe.price}
                        </bdi>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Shop;
