import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/about.css";

function About() {
  return (
    <div className="main-container">
      <Header />

      <div className="about_box">
        <div className="about_box_inner">
          <div className="about1_box">
            <img
              alt=""
              src="https://ak1.ostkcdn.com/img/mxc/06202024-about-hero-medium-desktop-1400x250-2.svg?imwidth=1920"
            />
          </div>
          <p>
            Beyond, Inc. (NYSE:BYON), based in Midvale, Utah, is an ecommerce
            expert with a singular focus: connecting consumers with products and
            services that unlock their homes’ potential. The Company owns
            Overstock, Bed Bath &amp; Beyond, Baby &amp; Beyond, Zulily, and
            other related brands and associated intellectual property. Its suite
            of online shopping brands features millions of products for various
            life stages that millions of customers visit each month.
          </p>
          <div className="about_team">
            <img
              style={{ marginLeft: "0" }}
              alt=""
              src="https://ak1.ostkcdn.com/img/mxc/06202024-about-executiveteam-headlineprimary-xsmall-desktop-1400x100-2.svg?imwidth=1920"
            />
            <div className="about_team_inner">
              <div className="about_team_body">
                <div>
                  <img
                    alt=""
                    src="https://ak1.ostkcdn.com/img/mxc/101623-PR-About-MLemonis-Headshot.jpg?imwidth=256"
                  />
                </div>
                <div class="ImageGridStyles_textWrapper__XR8eF">
                  <span class="ImageGridStyles_boldText__2csZf">
                    Marcus Lemonis
                  </span>
                  <span>Executive Chairman</span>
                </div>
              </div>
              <div className="about_team_body">
                <div>
                  <img
                    alt=""
                    src="https://ak1.ostkcdn.com/img/mxc/101623-PR-About-ALee-Headshot.jpg?imwidth=256"
                  />
                </div>
                <div class="ImageGridStyles_textWrapper__XR8eF">
                  <span class="ImageGridStyles_boldText__2csZf">
                    Adrianne Lee
                  </span>
                  <span>Chief Financial and Administrative Officer</span>
                </div>
              </div>
            </div>{" "}
            <div className="about_team_inner">
              <div className="about_team_body">
                <div>
                  <img
                    alt=""
                    src="https://ak1.ostkcdn.com/img/mxc/101623-PR-About-DNielsen-Headshot.jpg?imwidth=256"
                  />
                </div>
                <div class="ImageGridStyles_textWrapper__XR8eF">
                  <span class="ImageGridStyles_boldText__2csZf">
                    Dave Nielsen
                  </span>
                  <span>President</span>
                </div>
              </div>
              <div className="about_team_body">
                <div>
                  <img
                    alt=""
                    src="https://ak1.ostkcdn.com/img/mxc/05212024_Gruncha_headshot.jpg?imwidth=256"
                  />
                </div>
                <div class="ImageGridStyles_textWrapper__XR8eF">
                  <span class="ImageGridStyles_boldText__2csZf">
                    Guncha Mehta
                  </span>
                  <span>Chief Digital and Information Officer</span>
                </div>
              </div>
            </div>
            <div className="about_team_inner">
              <div className="about_team_body">
                <div>
                  <img
                    alt=""
                    src="https://ak1.ostkcdn.com/img/mxc/052024_August_Jennifer%20Evans.jpg?imwidth=3840"
                  />
                </div>
                <div class="ImageGridStyles_textWrapper__XR8eF">
                  <span class="ImageGridStyles_boldText__2csZf">
                    Jennifer Evans
                  </span>
                  <span>Chief Marketing Officer, Brand and Creative</span>
                </div>
              </div>
            </div>
          </div>

          <div className="about_team">
            <img
              style={{ marginLeft: "0" }}
              alt=""
              src="https://ak1.ostkcdn.com/img/mxc/06202024-about-boardofdirectors-headlineprimary-xsmall-desktop-1400x100-2.svg?imwidth=1920"
            />
            <div className="about_team_inner">
              <div className="about_team_body">
                <div>
                  <img
                    alt=""
                    src="https://ak1.ostkcdn.com/img/mxc/101623-PR-About-MLemonis-Headshot.jpg?imwidth=256"
                  />
                </div>
                <div class="ImageGridStyles_textWrapper__XR8eF">
                  <span class="ImageGridStyles_boldText__2csZf">
                    Marcus Lemonis
                  </span>
                  <span>Executive Chairman</span>
                </div>
              </div>
              <div className="about_team_body">
                <div>
                  <img
                    alt=""
                    src="https://ak1.ostkcdn.com/img/mxc/JoannaBurkey_2024_650x650.jpg?imwidth=256"
                  />
                </div>
                <div class="ImageGridStyles_textWrapper__XR8eF">
                  <span class="ImageGridStyles_boldText__2csZf">
                    Joanna Burkey
                  </span>
                  <span>Director</span>
                </div>
              </div>
              <div className="about_team_body">
                <div>
                  <img
                    alt=""
                    src="https://ak1.ostkcdn.com/img/mxc/101623-PR-About-BCorbus-Headshot.jpg?imwidth=256"
                  />
                </div>
                <div class="ImageGridStyles_textWrapper__XR8eF">
                  <span class="ImageGridStyles_boldText__2csZf">
                    Barclay F. Corbus
                  </span>
                  <span>Director</span>
                </div>
              </div>
              <div className="about_team_body">
                <div>
                  <img
                    alt=""
                    src="https://ak1.ostkcdn.com/img/mxc/101623-PR-About-WNettles-Headshot.jpg?imwidth=256"
                  />
                </div>
                <div class="ImageGridStyles_textWrapper__XR8eF">
                  <span class="ImageGridStyles_boldText__2csZf">
                    William Nettles
                  </span>
                  <span>Director</span>
                </div>
              </div>
            </div>{" "}
            <div className="about_team_inner">
              <div className="about_team_body">
                <div>
                  <img
                    alt=""
                    src="https://ak1.ostkcdn.com/img/mxc/101623-PR-About-RShapiro-Headshot.jpg?imwidth=256"
                  />
                </div>
                <div class="ImageGridStyles_textWrapper__XR8eF">
                  <span class="ImageGridStyles_boldText__2csZf">
                    Robert Shapiro
                  </span>
                  <span>Director</span>
                </div>
              </div>
              <div className="about_team_body">
                <div>
                  <img
                    alt=""
                    src="https://ak1.ostkcdn.com/img/mxc/101623-PR-About-JTobacco-Headshot.jpg?imwidth=256"
                  />
                </div>
                <div class="ImageGridStyles_textWrapper__XR8eF">
                  <span class="ImageGridStyles_boldText__2csZf">
                    Joseph J. Tabacco, Jr.
                  </span>
                  <span>Director</span>
                </div>
              </div>
            </div>
          </div>
          <div className="about_team">
            <img
              style={{ marginLeft: "0" }}
              alt=""
              src="https://ak1.ostkcdn.com/img/mxc/06202024-about-milestones-headlineprimary-xsmall-desktop-1400x100-2.svg?imwidth=1920"
            />
            <img
              alt=""
              src="https://ak1.ostkcdn.com/img/mxc/11162023-MOPS-timeline-desktop-01.svg?imwidth=1920"
            />
            <img
              alt=""
              src="https://ak1.ostkcdn.com/img/mxc/11162023-MOPS-timeline-desktop-02.svg?imwidth=1920"
            />
            <img
              alt=""
              src="https://ak1.ostkcdn.com/img/mxc/11162023-MOPS-timeline-desktop-03.svg?imwidth=1920"
            />
          </div>
          <div className="about_team">
            <img
              style={{ marginLeft: "0" }}
              alt=""
              src="https://ak1.ostkcdn.com/img/mxc/06202024-about-awards-headlineprimary-xsmall-desktop-1400x100-2.svg?imwidth=1920"
            />
            <div class="about_internal_txt">
              <p>
                We have long been recognized as one of the leading online retail
                companies in the U.S., receiving awards for customer service,
                company growth, technology innovations, and much more since our
                inception in 1999. Below are highlights of some of the recent
                corporate recognitions we’ve received:
              </p>
              <ul>
                <li>Named 2023 Retail Trailblazer by Home Textiles Today</li>
              </ul>
              <p></p>
              <ul>
                <li>Women Tech Council’s Shatter List (2018-2023)</li>
              </ul>
              <p></p>
              <ul>
                <li>
                  Parity.org Best Companies for Women to Advance (inaugural list
                  in 2020-2023)
                </li>
              </ul>
              <p></p>
              <ul>
                <li>
                  Parity.org Best Companies for People of Color to Advance
                  (inaugural list in 2023)
                </li>
              </ul>
              <p></p>
              <ul>
                <li>MobileWebAwards for best retail mobile app (2012-2018)</li>
              </ul>
              <p></p>
              <ul>
                <li>
                  Loyalty360 Awards for excellence in customer service
                  (2019-2023)
                </li>
              </ul>
              <p></p>
              <ul>
                <li>
                  Best of State Awards in General Merchandising (2017-2021)
                </li>
              </ul>
              <p></p>
              <ul>
                <li>
                  MountainWest Capital Network’s (MWCN) Utah 100 for fastest
                  growing companies in the state (2002-2023)
                </li>
              </ul>
              <p></p>
              <ul>
                <li>
                  LEED Gold-Certification for our Peace Coliseum corporate
                  headquarters in Midvale, Utah
                </li>
              </ul>
              <p></p>
              <p>
                <em>
                  <strong>
                    Prior to August 2023, all recognitions were given to
                    Overstock.com, Inc., our former company name.
                  </strong>
                </em>
              </p>
            </div>

            <div className="about_bot_grid">
              <img
                alt=""
                src="	https://ak1.ostkcdn.com/img/mxc/101623-PR-About-Awards-ABA-Tile-2.jpg?imwidth=1920"
              />
              <img
                alt=""
                src="https://ak1.ostkcdn.com/img/mxc/102023-PR-About-Awards-NFR-Tile.jpg?imwidth=1920"
              />
              <img
                alt=""
                src="https://ak1.ostkcdn.com/img/mxc/101623-PR-About-Awards-T100-Tile-4.jpg?imwidth=1920"
              />

              <img
                alt=""
                src="https://ak1.ostkcdn.com/img/mxc/101623-PR-About-Awards-WMA-Tile-5.jpg?imwidth=1920"
              />
              <img
                alt=""
                src="https://ak1.ostkcdn.com/img/mxc/102023-PR-About-Awards-Loyalty360-Tile.jpg?imwidth=1920"
              />
              <img
                alt=""
                src="	https://ak1.ostkcdn.com/img/mxc/102023-PR-About-Awards-WTC-Tile.jpg?imwidth=1920"
              />

              <img
                alt=""
                src="https://ak1.ostkcdn.com/img/mxc/102023-PR-About-Awards-BoS-Tile.jpg?imwidth=1920"
              />
              <img
                alt=""
                src="https://ak1.ostkcdn.com/img/mxc/102023-PR-About-Awards-LEED-Tile.jpg?imwidth=1920"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
