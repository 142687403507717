import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import "../css/cartpage.css";
import { useNavigate, Link } from "react-router-dom";

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/checkout");
  };
  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(product.price);
        total += price * item.quantity || price * 1;
      }
    });
    return total;
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const item = cartItems.find((cartItem) => cartItem.id === itemId);
    if (!item) return 0;
    const price = parseFloat(product.price);
    return price * quantity || price;
  };

  const handleInputChange = (id, event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      updateQuantity(id, newQuantity);
    }
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div
          className="detail_top_box"
          style={{
            background: "#333333",
          }}
        ></div>

        <div className="cart_box">
          <div className="cart_box_inner">
            <form>
              <h2>You Have {cartItems.length} Item In Your Cart</h2>
              <table className="shop_table">
                <thead>
                  <tr>
                    <th class="product-name">Product</th>
                    <th class="product-price">Price</th>
                    <th class="product-quantity">Quantity</th>
                    <th class="product-subtotal">Subtotal</th>
                    <th class="product-remove">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item, index) => {
                    const product = getProductById(item.id);
                    const itemTotal = calculateItemTotal(
                      item.id,
                      item.quantity
                    );
                    return (
                      <tr key={index}>
                        <td class="product-name" data-title="Product">
                          <div class="fusion-product-name-wrapper">
                            <span class="product-thumbnail">
                              <Link to={`/shopDetails/${item.id}`}>
                                <img
                                  width="500"
                                  height="500"
                                  src={product.images[0]}
                                  class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail lazyautosizes ls-is-cached lazyloaded"
                                  alt=""
                                  sizes="90px"
                                />
                              </Link>
                            </span>

                            <div class="product-info">
                              <Link
                                to={`/shopDetails/${item.id}`}
                                class="product-title"
                              >
                                {product.name}
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td class="product-price" data-title="Price">
                          <span class="woocommerce-Price-amount amount">
                            <bdi>
                              <span class="woocommerce-Price-currencySymbol">
                                $
                              </span>
                              {product.price}
                            </bdi>
                          </span>{" "}
                        </td>
                        <td class="product-quantity" data-title="Quantity">
                          <div
                            class="quantity_buttons_added"
                            style={{
                              justifyContent: "center",
                              marginBottom: "0",
                            }}
                          >
                            <input
                              type="button"
                              value="-"
                              class="minus"
                              style={{ width: "48px", height: "40px" }}
                              onClick={() =>
                                updateQuantity(
                                  item.id,
                                  Math.max(item.quantity - 1, 0)
                                )
                              }
                            />

                            <input
                              type="text"
                              id="quantity_673c4dc74d083"
                              class="input-text qty text"
                              name="cart[88fee0421317424e4469f33a48f50cb0][qty]"
                              value={item.quantity}
                              onChange={(e) => handleInputChange(item.id, e)}
                              aria-label="Product quantity"
                              size="4"
                              min="0"
                              max=""
                              step="1"
                              placeholder=""
                              inputmode="numeric"
                              autocomplete="off"
                              style={{
                                width: "40px",
                                height: "40px",
                                textAlign: "center",
                              }}
                            />
                            <input
                              type="button"
                              value="+"
                              class="plus"
                              style={{ width: "48px", height: "40px" }}
                              onClick={() =>
                                updateQuantity(item.id, item.quantity + 1)
                              }
                            />
                          </div>
                        </td>
                        <td class="product-subtotal" data-title="Total">
                          <span class="woocommerce-Price-amount amount">
                            <bdi>
                              <span class="woocommerce-Price-currencySymbol">
                                $
                              </span>
                              {itemTotal.toFixed(2)}
                            </bdi>
                          </span>{" "}
                        </td>
                        <td class="product-remove">
                          <a
                            href="#"
                            class="remove"
                            aria-label="Remove Ankle Height Garden Shoes Waterproof"
                            data-product_id="2673"
                            data-product_sku=""
                            onClick={() => removeFromCart(item.id)}
                          >
                            ×
                          </a>{" "}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </form>
            <div className="cart-collaterals">
              <div className="cart_totals ">
                <h2>Cart totals</h2>
                <table>
                  <tbody>
                    <tr class="cart-subtotal">
                      <th>Subtotal</th>
                      <td data-title="Subtotal">
                        <span class="woocommerce-Price-amount amount">
                          <bdi>
                            <span class="woocommerce-Price-currencySymbol">
                              $
                            </span>
                            {calculateTotal().toFixed(2)}
                          </bdi>
                        </span>
                      </td>
                    </tr>

                    <tr class="order-total">
                      <th>Total</th>
                      <td data-title="Total">
                        <strong>
                          <span class="woocommerce-Price-amount amount">
                            <bdi>
                              <span class="woocommerce-Price-currencySymbol">
                                $
                              </span>
                              {calculateTotal().toFixed(2)}
                            </bdi>
                          </span>
                        </strong>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style={{ textAlign: "right" }}>
                  <Link
                    to="/checkout"
                    className="wc-proceed-to-checkout"
                    onClick={toCheckOut}
                  >
                    Proceed to checkout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartPage;
