import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/privacy.css";
import { Link } from "react-router-dom";

function Privacy() {
  const toBottom = () => {
    window.scrollTo({
      bottom: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_box_inner">
          <div className="privacy_body">
            <h1>Privacy Policy</h1>
            <div className="privacy_body_clock">Last Updated: 28 June 2024</div>
            <br />
            <p>
              We care about our customers and know that your privacy is
              important to you. This Privacy Policy is our way of explaining how
              we collect personal information, why we need it, and how we use
              it.
            </p>
            <p>
              Please see our Privacy Snapshot below for an overview of our
              privacy practices.
            </p>
            <h4>Scope of this Policy</h4>
            <p>
              This Privacy Policy applies to your use of our websites (the
              "Sites") and our mobile applications (the "Apps" together with the
              Sites, the "Services") operated by Wayfair LLC and Wayfair Stores
              Limited, including our brands Wayfair, Joss & Main, AllModern,
              Birch Lane, and Perigold ("Wayfair", "we," or "us").{" "}
            </p>
            <p>
              This Privacy Policy also applies to the personal information you
              provide to us through other interactions and correspondence. This
              could be, for example, when you share your information with our
              service representatives (e.g. a customer service agent, design
              professional, or an installation or assembly pro), through any of
              our marketing programs, or send us a letter, email, or chat.
            </p>
            <p>
              We collect information that identifies, relates to, describes,
              references, is capable of being associated with, or could
              reasonably be linked, directly or indirectly, with a particular
              consumer or device (”personal information”). In particular, we
              have collected the following categories of personal information
              from our customers within the last twelve (12) months:
            </p>
            <h4>Personal Information We Collect</h4>
            <p>
              <strong>Information you provide to us:</strong>
            </p>
            <ul>
              <li>
                <p>
                  <strong>Your contact details and account data</strong>
                  if you establish an online account with us (such as your name,
                  email address, mailing address, phone number, and password).
                </p>
              </li>
              <li>
                <p>
                  <strong>Additional profile information</strong>
                  you may choose to provide (such as date of birth, gender,
                  location, and preferred language). We may also ask you to
                  provide additional information to identify yourself such as a
                  government issued ID number if you do not have an account with
                  us (e.g. when making or returning purchases at our
                  brick-and-mortar stores in the United States).
                </p>
              </li>
              <li>
                <p>
                  <strong>Your payment information</strong>
                  if you make a purchase via the Services. We use third-party
                  payment processors, such as Stripe, to process your payments.
                  These third parties will collect the information necessary to
                  effectuate your transaction with us, such as credit/debit card
                  number, security/CVV code, and expiration date. Our payment
                  processors may handle your payment information in accordance
                  with their own privacy policies. Wayfair does not have access
                  to your full payment information.
                </p>
              </li>
              <li>
                <p>
                  <strong>Tax-related information,</strong>
                  such as your Social Security number or your entity
                  identification number or your VAT registration number (or
                  similar), if you (i) participate in our product review program
                  or (ii) are a B2B customer. We will use this information to
                  prepare your Form 1099 and other tax-related documentation
                  related to the product review program, verify your business if
                  you are a B2B customer, and to provide B2B customers with tax
                  compliant invoices.
                </p>
              </li>
              <li>
                <p>
                  <strong>Your financing information</strong>
                  if you apply for financing solutions, such as our co-branded
                  credit card or installment payment offering. As part of your
                  application, we may collect information such as Social
                  Security number, date of birth, and income. Our financing
                  solutions are offered by third-party providers, such as
                  Citibank. Your personal information will be used by these
                  financing providers in accordance with their own privacy
                  policies.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Your communications and contact history with us
                  </strong>
                  (such as emails, text messages, service call recordings, chat
                  and instant messages, or communications with our social media
                  channels).
                </p>
              </li>
              <li>
                <p>
                  <strong>Other information you choose to provide</strong>
                  (such as product reviews, forum posts, survey responses,
                  participation in promotions, and other user-generated content,
                  including, but not limited to, images you upload).
                </p>
              </li>
            </ul>
            <p>
              Where applicable, we may indicate whether and why you must provide
              us with your personal information, as well as the consequences of
              failing to do so. For example, it may be necessary for you to
              disclose certain personal information in order for us to provide
              the Services to you.
            </p>
            <p>
              <strong>
                Subject to our Cookie Policy, we may automatically collect
                Information from your use of the Services:
              </strong>
            </p>
            <ul>
              <li>
                <p>
                  <strong>Purchase history and saved items</strong>
                  (such as what you bought, what you've placed in or removed
                  from your cart, or what items you've saved).
                </p>
              </li>
              <li>
                <p>
                  <strong>Log data and device information</strong>
                  (such as details about how you've used the Services, IP
                  address, access dates and times, hardware and software
                  information, device information, device event information,
                  unique identifiers, crash data, cookie data, location data,
                  and page/product views or clicks).
                </p>
              </li>
              <li>
                <p>
                  <strong>Geo-location information</strong>
                  (such as IP address, browser information, or mobile GPS
                  information).
                </p>
              </li>
            </ul>
            <p>
              We use cookies and similar technologies (such as web beacons and
              local storage technologies) to collect such data. Please see our
              Cookie Policy for more information.
            </p>
            <p>
              <strong>Information we may collect from third parties,</strong>{" "}
              including third party services (for example if you link, connect,
              or login to the Services with your Google, Facebook, Instagram, or
              Twitter accounts or choose to participate in one of our marketing
              programs) or other sources (such as your contacts who want to
              invite you to use the Services or brands we partner with):
            </p>
            <ul>
              <li>
                <p>
                  <strong>Contact details</strong>
                  (such as your name, email address, mailing address, and phone
                  number).
                </p>
              </li>
              <li>
                <p>
                  <strong>Social media profile information</strong>
                  publicly available on your social media account (such as your
                  Twitter or Instagram handle, profile description, posts
                  tagging @wayfair etc.).
                </p>
              </li>
            </ul>
            <h4>How We Use Personal Information</h4>
            <p>
              We use personal information for the following purposes or as
              otherwise described at the time of collection:
            </p>
            <p>
              <strong>Providing and supporting our Services.</strong> We use
              personal information to operate, maintain, and provide you with
              our Services. In particular, we will use personal information to
              perform our contractual obligation under our terms of use, such as
              to allow you to create an account and make purchases via the
              Services, facilitate financing solutions, and for us to take
              payment, deliver your purchases, and process returns.
            </p>
            <p>
              <strong>Communicate with you about our Services.</strong>It is in
              our legitimate business interests to use personal information to
              respond to your requests, provide customer support, and
              communicate with you about our Services, including by sending
              announcements, updates, security alerts, and support and
              administrative messages, and to administer surveys, sweepstakes,
              and contests.
            </p>
            {/* 写到这 */}
            <p>
              <strong>
                Improve, monitor, personalize, and protect our Services.{" "}
              </strong>
              It is in our legitimate business interests to improve and keep our
              Services safe for our users, which includes:
            </p>
            <ul>
              <li>
                Understanding your needs and interests, and personalizing your
                experience with the Services and our communications.
              </li>
              <li>
                Troubleshooting, testing, and research, and keeping the Services
                secure.
              </li>
              <li>
                Investigating and protecting against fraudulent, harmful,
                unauthorized, or illegal activity.
              </li>
            </ul>
            <p>
              <strong>Research and development.</strong>We may use personal
              information for research and development purposes in our
              legitimate business interests, including to analyze and improve
              the Services and our business, and to train our staff. As part of
              these activities, we may create or use aggregated, de-identified
              or other anonymized data from personal information we collect. We
              make personal information into anonymized data by removing
              information that makes the data personally identifiable to you. We
              may use this anonymized data and share it with third parties for
              our lawful business purposes, including to analyze and improve the
              Services and promote our business.
            </p>
            <p>
              <strong>Marketing and advertising. </strong>We, our service
              providers, and our third-party advertising partners may collect
              and use personal information for the following marketing and
              advertising purposes:
            </p>
            <ul>
              <li>
                <strong>Direct marketing.</strong> We may send you direct
                marketing communications, by email or otherwise, as permitted by
                law. See Your Privacy Rights and Choices below for information
                on how to stop receiving direct marketing communications.
              </li>
              <li>
                <strong>Interest-based advertising. </strong>We engage our
                advertising partners, including third party advertising
                companies (such as Google) and social media companies, to
                display ads around the web. These companies may use cookies and
                similar technologies to collect information (including, subject
                to our Cookie Policy, the automatically-collected data described
                above) about your interactions over time across our Services,
                our communications, and other online services, and use that
                information to serve online ads that they think will interest
                you. This is called interest-based advertising. For more
                information, please visit our Cookie Policy.
              </li>
            </ul>
            <p>
              Except where consent is required, we undertake such marketing and
              advertising on the basis of our legitimate business interests.
              Where we seek your consent, you may withdraw your consent at any
              time.
            </p>
            <p>
              We do not engage in profiling in furtherance of decisions that
              produce legal or similarly significant effects concerning
              consumers, as defined in the Colorado Privacy Act.
            </p>
            <p>
              <strong>Compliance and protection.</strong>We may use personal
              information in our legitimate business interests to enforce and
              comply with our terms and policies, and to defend us against legal
              claims or disputes including:
            </p>
            <ul>
              <li>
                Protecting our, your or others’ rights, privacy, safety, or
                property (including by making and defending legal claims).
              </li>
              <li>
                Auditing our internal processes for compliance with legal and
                contractual requirements and internal policies.
              </li>
              <li>
                Enforcing the terms and conditions that govern the Services.
              </li>
              <li>
                Preventing, identifying, investigating, and deterring
                fraudulent, harmful, unauthorized, unethical or illegal
                activity, including cyberattacks and identity theft.
              </li>
            </ul>
            <p>
              Some processing may also be necessary to comply with applicable
              laws, lawful requests and legal process, such as to respond to
              subpoenas or requests from government authorities, and other legal
              obligations such as to keep records of transactions.
            </p>
            <h4>How We Share Personal Information</h4>
            <p>
              We share personal information with the following categories of
              recipients:
            </p>
            <ul>
              <li>Within Wayfair.</li>
              <li>
                Companies and individuals that help us deliver our products to
                you, such as suppliers, payment service providers, logistics
                professionals, gift card suppliers, repair and assembly
                providers, and delivery and freight companies.
              </li>
              <li>
                Professional service providers who help us run our business and
                provide support services to us, such as marketing companies,
                including email providers and mobile marketing providers,
                software vendors, and consultants.
              </li>
              <li>
                Advertising partners, including for the interest-based
                advertising purposes described above.
              </li>
              <li>
                Financing providers. By choosing to apply for a financing
                solution offered by a third-party financing provider, such as
                Citibank, you direct us to share certain personal information
                with that provider to facilitate your application. Your personal
                information will be used by that provider in accordance with
                their own privacy policy.
              </li>
              <li>
                Government and law enforcement agencies and private parties, as
                we believe in good faith to be necessary and appropriate to (i)
                comply with a legal process, subpoena, order, or other legal or
                regulatory requirement; (ii) enforce our terms of use or other
                policies; (iii) pursue available legal remedies or defend legal
                claims; or (iv) otherwise for the compliance and protection
                purposes described above.
              </li>
              <li>
                Acquirers and other relevant participants in business
                transactions (or negotiations for such transactions) involving a
                corporate divestiture, merger, consolidation, acquisition,
                reorganization, sale or other disposition of all or any portion
                of the business or assets of, or equity interests in, Wayfair
                (including, in connection with a bankruptcy or similar
                proceedings).
              </li>
            </ul>
            <p>
              We may anonymize your personal information and share such
              anonymized information with third parties for any purpose
              permitted by applicable laws.
            </p>
            <h4>Blogs, Reviews, and Forums</h4>
            <p>
              The Services may from time to time offer publicly-accessible
              blogs, reviews, or forums. Any information you provide in these
              spaces may be read, collected, and used by others who access them.
              To request removal of your information from blogs, customer
              reviews, or forums, please see Your Privacy Rights and Choices
              below. Please note that in some cases we may be unable to remove
              your information.
            </p>
            <h4>Social Media Plugins</h4>
            <p>
              The Sites and the Apps use social plugins, which allow you to
              interact with us through your Facebook, Instagram, Twitter, and
              other social media accounts. If you visit a page on our Sites or
              Apps that contains such a plugin, your browser establishes a
              direct connection to that social media site’s webservers. The
              content of the plugin is transmitted by the social media site
              directly to your browser and incorporated by the social media site
              into the Sites or Apps. By integrating the plugins, the social
              media site receives the information that your browser has accessed
              on the corresponding page of our Sites or Apps, even if you do not
              have an account or are currently not logged in to your account
              with us. We have no control over the extent and use of information
              that the social media site collects using this plugin.
            </p>
            <p>
              If you interact with a social media plugin by, for example,
              clicking a "Like" button or leaving a comment, the corresponding
              information is also transmitted directly to the social media
              server and stored there. Depending on your settings, the
              information will also be posted on the social media site and
              displayed to your contacts or friends on the social media site.
              The social media site may use this information for the purpose of
              advertising, market research, and tailor-made pages.
            </p>
            <p>
              To understand what information social media sites collect when you
              interact with their plug-ins, please review their privacy
              policies. You may also have the option to change your settings in
              the social media site to prevent the collection of your
              information.
            </p>
            <h4>Job Applicants</h4>
            <p>
              When you visit the careers portion of our website, our Candidate
              Privacy Policy applies.
            </p>
            <h4>Investors</h4>
            <p>
              You may provide information to us when visiting the investors page
              of our website, such as your name and contact information. We use
              this information to communicate with you and respond to your
              requests. We may also use and share this information to provide
              improved administration of the website, and as otherwise
              necessary: (a) to comply with relevant laws or to respond to
              subpoenas or warrants served on us; (b) to protect and defend the
              rights or property of us or others; (c) in connection with a legal
              investigation; and/or (d) to investigate or assist in preventing
              any violation or potential violation of the law, this Privacy
              Policy, or our terms of use.
            </p>
            <h4>Our Security Measures</h4>
            <p>
              We use appropriate technical and organizational safeguards
              designed to protect your information from unauthorized use,
              disclosure, and loss. We use encryption technology, including
              current industry-standard encryption protocols, to protect
              personal information in certain areas of our Sites during
              transmission across the Internet. We use PCI-compliant payment
              service providers. If you have questions about the security of
              your information, please contact us
              <Link to="/" onClick={toBottom}>
                here.
              </Link>
            </p>
            <p>
              We encourage you to take reasonable measures to protect your
              password and your computer to prevent unauthorized access to your
              account. Remember to sign out of your account and close your
              browser window if you are using a shared computer in a public
              place.
            </p>
            <h4>Data Retention</h4>
            <p>
              We will keep your information for as long as you have an account
              or as long as we need it to fulfill the purpose for which it was
              collected or disclosed to you at the time of collection. We may
              also keep some of your information as required to meet legal or
              regulatory requirements, resolve disputes, prevent fraud or abuse,
              enforce our terms of use, or for other legitimate business
              purposes.
            </p>
            <p>
              When we no longer have a need to keep your information, we will
              either delete it from our systems or anonymize it so that it no
              longer identifies you.
            </p>
            <h4>Children's Privacy</h4>
            <p>
              The Service is not directed to children, and we do not knowingly
              collect information from children under 16. If you are a parent or
              guardian and believe that we have information about your child,
              please contact us as described in the
              <Link to="/" onClick={toBottom}>
                {" "}
                Contact Us{" "}
              </Link>
              section below.
            </p>
            <h4>California Minors</h4>
            <p>
              California residents under 18 years of age may have the personal
              information that they posted on the Sites deleted by sending an
              email to <Link to="mailto:work@woksi.cfd"> work@woksi.cfd</Link>{" "}
              and requesting deletion.
            </p>
            <h4>Your Privacy Rights and Choices</h4>
            <p>
              <strong>Modify your account information.</strong>If you have a
              Wayfair account, you can review and update certain account
              information by logging into your account. You may also contact us
              to request deletion of your account. If we delete your account,
              you will not be able to access it.
            </p>
            <p>
              <strong>Opt-out of marketing communications.</strong>You can stop
              receiving direct marketing messages from us at any time by
              updating your preferences here or by clicking any "unsubscribe"
              link in any marketing message you receive from us. It may take up
              to 48 hours to process your request and update our systems. If you
              request that we stop sending you marketing messages, we will
              continue to send you administrative and service communications
              (such as order updates and other important or transactional
              information). If you use more than one name or email address when
              communicating with Wayfair or using the Service, you may continue
              to receive communications from us to any name or email address not
              specified in your opt-out request.
            </p>
            <p>
              <strong>Opt-out of text messages.</strong>You can stop receiving
              text messages at any time by texting "STOP". After you send
              "STOP", we may send you an additional text message to confirm that
              you have been unsubscribed. You will no longer receive text
              messages from that short code, but you may receive text messages
              if you are subscribed to other text lists. If at any time you have
              questions about the text messages, text "HELP". After you send
              "HELP" we will respond with instructions on how to use our service
              as well as how to unsubscribe. Message and data rates may apply.
            </p>
            <p>
              <strong>Online tracking opt-out. </strong>You can opt out of the
              sale of or processing of your personal information for targeted
              advertising purposes and limit interest-based advertising as
              described on our Your Privacy Rights and Choices page.
            </p>
            <p>
              <strong>Personal information requests.</strong>Depending on your
              location and the nature of your interactions with our Services,
              you may request the following in relation to your personal
              information:
            </p>
            <ul>
              <li>
                <p>
                  <strong>Information</strong>
                  about how we have collected and used your personal
                  information. We have made this information available to you
                  without having to request it by including it in this Privacy
                  Policy.
                </p>
              </li>
              <li>
                <p>
                  <strong>Access</strong>
                  to a copy of the personal information that we have collected
                  about you. Where applicable, we will provide the information
                  in a portable, machine-readable, readily-usable format.
                </p>
              </li>
              <li>
                <p>
                  <strong>Correction</strong>
                  of personal information that is inaccurate or out of date.
                </p>
              </li>
              <li>
                <p>
                  <strong>Deletion</strong>
                  of personal information that we no longer need to provide the
                  Services or for other lawful purposes.
                </p>
              </li>
              <li>
                <p>
                  <strong>Additional rights</strong>, such as to{" "}
                  <strong>object</strong>
                  to and request that we <strong>restrict</strong>
                  our use of your personal information, and where applicable,
                  you may <strong>withdraw</strong>
                  your consent.
                </p>
              </li>
            </ul>
            <p>To exercise the above rights:</p>
            <ul>
              <li>Fill out the Privacy Rights Request Form.</li>
              <li>Visit our "Your Privacy Rights & Choices" page.</li>
              <li>
                Contact us at{" "}
                <Link to="mailto:work@woksi.cfd">work@woksi.cfd</Link> or at the
                addresses provided in the <Link to="/contact">Contact Us</Link>{" "}
                section below.
              </li>
            </ul>
            <p>
              Prior to responding to your requests, we may verify your identity
              by matching any requested identifying information you provide
              against the information we have about you. Depending on your
              jurisdiction, you may designate an authorized agent to make a
              request on your behalf. We will require the authorized agent to
              have a written authorization confirming that authority.
            </p>
            <p>
              Wayfair will never discriminate against you for exercising any of
              these rights, but you may lose access to certain functionality or
              the ability to interact with certain programs or offers due to
              changes in the personal information Wayfair has access to after
              complying with your privacy requests.
            </p>
            <p>
              <strong>Right to complain.</strong> You may have the right to
              lodge a complaint with our lead data protection authority in the
              EU, the Irish Data Protection Commissioner
              (https://www.dataprotection.ie) or with the data protection
              authority where you reside. Residents of the UK may choose to make
              their complaint to the Information Commissioner’s Office
              (https://ico.org.uk).
            </p>
            <p>
              <strong>Limits on your choices.</strong> In some instances, your
              choices may be limited, such as where fulfilling your request
              would impair the rights of others, our ability to provide a
              service you have requested, or our ability to comply with our
              legal obligations and enforce our legal rights. If you are not
              satisfied with how we address your request, you may submit a
              complaint by contacting us as provided in the
              <Link to="/contact">Contact Us </Link> section below.
            </p>
            <h4>Information for Visitors from Outside of the United States</h4>
            <p>
              Wayfair is headquartered in the United States. We may transfer
              your personal information to our affiliates and service providers
              in the United States and other jurisdictions. Please note that
              data protection laws of the United States and other jurisdictions
              may not be as comprehensive as those laws or regulations in your
              country or may otherwise differ from the laws in your country.
            </p>
            <p>
              When we engage in cross-border data transfers, we will ensure that
              relevant safeguards are in place to afford adequate protection for
              personal information, and we will comply with applicable data
              protection laws. For example, where our Service targets
              individuals in the European Economic Area, Switzerland or the
              United Kingdom, we may rely on an EU Commission or UK government
              adequacy decision or on contractual protections for the transfer
              of personal information. For more information about how we
              transfer personal information internationally, please contact us
              as set out in the <Link to="/contact">Contact Us </Link> section
              below.
            </p>
            <h4>Changes to this Privacy Policy</h4>
            <p>
              We may change this Privacy Policy from time to time, including to
              reflect new ways that we process your information. When we post
              modifications to this Privacy Policy, we will revise the "Last
              Updated" date at the top of this page. The modified Privacy Policy
              will be effective immediately upon posting on the Services.
            </p>
            <h4>Contact Us</h4>
            <p>
              Wayfair LLC is the entity responsible for your personal
              information, unless you’re a user of our Sites or Apps based in
              Europe in which case Wayfair Stores Limited is the data controller
              of your personal information.
            </p>
            <p>
              Please don't hesitate to contact us at
              <Link to="mailto:work@woksi.cfd"> work@woksi.cfd</Link>, or in
              writing at:
            </p>
            <p>
              Attention: Legal Department
              <br />
              Wayfair LLC
              <br />4 Copley Place
              <br />
              Boston, MA 02116
            </p>
            <p>or</p>
            <p>
              Attn: Data Protection Officer <br />
              Wayfair Stores Limited <br />
              Wayfair House
              <br /> Tuam Road
              <br /> Galway, Co. Galway <br />
              Ireland
            </p>
            <p>If you are based in the UK, you may contact us at:</p>
            <p>
              Attention: Legal Department
              <br />
              Wayfair Stores Limited
              <br />
              Angel House, 338 Goswell Rd, L4
              <br />
              London England EC1V 7LQ
            </p>
            <h4>Privacy Snapshot</h4>
            <p>
              Here is a high-level snapshot of our privacy practices, but you
              need to read the entire Privacy Policy for complete information.
            </p>
            <table>
              <thead>
                <tr>
                  <th>Data Categories Collected</th>
                  <th>How We Collect</th>
                  <th>Primary Purposes of Processing</th>
                  <th>Key Disclosures / Can You Limit Sharing?</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Identifiers such as a real name, Internet Protocol address,
                    email address, or other similar identifiers
                  </td>
                  <td>
                    When you visit or use our Services; from third-party sites
                    and services
                  </td>
                  <td>
                    To provide our Services; to improve, monitor, and
                    personalize our Services; to communicate with you; for
                    marketing and advertising
                  </td>
                  <td>
                    Service providers: No Financing providers: No Advertising
                    Partners: Yes
                  </td>
                </tr>
                <tr>
                  <td>
                    Personal information categories listed in the California
                    Customer Records Statute (e.g., name, contact details)
                  </td>
                  <td>
                    When you visit or use our Services; from third-party sites
                    and services
                  </td>
                  <td>
                    To provide our Services; to improve, monitor, and
                    personalize our Services; to communicate with you; for
                    marketing and advertising
                  </td>
                  <td>
                    Service providers: No Financing providers: No Advertising
                    Partners: Yes
                  </td>
                </tr>
                <tr>
                  <td>
                    Commercial information, including but not limited to records
                    of products or services purchased{" "}
                  </td>
                  <td>When you visit or use our Services</td>
                  <td>
                    To provide our Services; to improve, monitor, and
                    personalize our Services; to communicate with you; for
                    marketing and advertising
                  </td>
                  <td>Service providers: No Advertising Partners: Yes</td>
                </tr>
                <tr>
                  <td>
                    Internet or other electronic network activity information,
                    including but not limited to browsing history and search
                    history
                  </td>
                  <td>When you visit or use our Services</td>
                  <td>
                    To improve, monitor, and personalize our Services; to
                    communicate with you; for marketing and advertising
                  </td>
                  <td>Service providers: No Advertising Partners: Yes</td>
                </tr>
                <tr>
                  <td>
                    Inferences drawn from information to create a profile about
                    your behavior, preferences, etc.
                  </td>
                  <td>
                    When you visit or use our Services; from third-party sites
                    and services
                  </td>
                  <td>
                    To improve, monitor, and personalize our Services; to
                    communicate with you; for marketing and advertising
                  </td>
                  <td>Service providers: No Advertising Partners: Yes</td>
                </tr>
                <tr>
                  <td>Geolocation data</td>
                  <td>When you visit or use our Services</td>
                  <td>
                    To improve, monitor, and personalize our Services; to
                    communicate with you; for marketing and advertising
                  </td>
                  <td>Service providers: No Advertising Partners: Yes</td>
                </tr>
                <tr>
                  <td>Sensitive information</td>
                  <td>When you visit or use our Services</td>
                  <td>To provide our Services</td>
                  <td>Service providers: No Financing providers: No</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
