import "../css/header.css";
import React from "react";
import { Link } from "react-router-dom";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="header_left">
          <Link to="/">
            <img
              fetchpriority="high"
              decoding="async"
              width="703"
              height="689"
              src="https://yaotradinginc.com/wp-content/uploads/2024/08/微信截图_20240809180025.png"
              alt=""
              class="img-responsive wp-image-2704 disable-lazyload"
              srcset="https://yaotradinginc.com/wp-content/uploads/2024/08/微信截图_20240809180025-200x196.png 200w, https://yaotradinginc.com/wp-content/uploads/2024/08/微信截图_20240809180025-400x392.png 400w, https://yaotradinginc.com/wp-content/uploads/2024/08/微信截图_20240809180025-600x588.png 600w, https://yaotradinginc.com/wp-content/uploads/2024/08/微信截图_20240809180025.png 703w"
              sizes="(max-width: 1075px) 100vw, (max-width: 640px) 100vw, 703px"
            />
          </Link>
        </div>

        <div className="header_right">
          <ul>
            <li>
              <Link to="/" onClick={toTop}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/shop" onClick={toTop}>
                Shop
              </Link>
            </li>
            <li>
              <Link to="/cartpage" onClick={toTop}>
                Cart
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toTop}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
