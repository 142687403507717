const productData = [
  {
    id: 1,
    name: "Ankle Height Garden Shoes Waterproof",
    price: "60.00",
    description: [
      "Care instructions",
      "Machine Wash",
      "Sole material",
      "Rubber",
      "Shaft height",
      "6.7 Inches",
      "Outer material",
      "Rubber",
    ],
    images: ["https://yaotradinginc.com/wp-content/uploads/2024/05/2.jpg"],
  },
  {
    id: 2,
    name: "Ankle Rubber Rain Boot",
    price: "42.00",
    description: [
      "Sole material",
      "Rubber",
      "Shaft height",
      "Ankle",
      "Shaft circumference",
      "10 Centimeters",
      "Outer material",
      "Rubber",
    ],
    images: ["https://yaotradinginc.com/wp-content/uploads/2024/05/7.jpg"],
  },
  {
    id: 3,
    name: "Everywear Rain Boots Ankle",
    price: "30.00",
    description: [
      "Sole material",
      "Patented Everywear",
      "Shaft height",
      "Ankle Inches",
      "Shaft circumference",
      "32.5 Centimeters",
      "Outer material",
      "Rubber",
    ],
    images: ["https://yaotradinginc.com/wp-content/uploads/2024/05/1.jpg"],
  },
  {
    id: 4,
    name: "Fishing Watertight Rain Shoes",
    price: "55.00",
    description: [
      "Sole material",
      "Rubber",
      "Shaft height",
      "Ankle",
      "Shaft circumference",
      "33",
      "Outer material",
      "Rubber",
    ],
    images: ["https://yaotradinginc.com/wp-content/uploads/2024/05/9.jpg"],
  },
  {
    id: 5,
    name: "Garden Fishing Outdoor Deck Boots",
    price: "82.00",
    description: [
      "Sole material",
      "Rubber",
      "Outer material",
      "Rubber",
      "Closure type",
      "Slip-On",
      "Water resistance level",
      "Waterproof",
    ],
    images: ["https://yaotradinginc.com/wp-content/uploads/2024/05/10.jpg"],
  },
  {
    id: 6,
    name: "Men Waterproof Lightweight",
    price: "70.00",
    description: [
      "Sole material",
      "Rubber",
      "Outer material",
      "Rubber",
      "Closure type",
      "Pull on",
      "Water resistance level",
      "Waterproof",
    ],
    images: ["https://yaotradinginc.com/wp-content/uploads/2024/05/5.jpg"],
  },
  {
    id: 7,
    name: "Men’s Ankle Rain Boots",
    price: "63.00",
    description: [
      "Sole material",
      "Rubber",
      "Shaft height",
      "Ankle",
      "Shaft circumference",
      "16 Inches",
      "Outer material",
      "Rubber & Neoprene",
    ],
    images: ["https://yaotradinginc.com/wp-content/uploads/2024/05/3.jpg"],
  },
  {
    id: 8,
    name: "Men’s Mid Ankle Boot",
    price: "70.00",
    description: [
      "Fabric type",
      "100% Synthetic",
      "Sole material",
      "100% Synthetic",
      "Platform height",
      "1 Inch",
    ],
    images: ["https://yaotradinginc.com/wp-content/uploads/2024/05/6.jpg"],
  },
];

export default productData;
