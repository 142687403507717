import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { addToCart } = useContext(CartContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [quantities, setQuantities] = useState(1);

  const handleAddToCart = () => {
    addToCart(product.id, quantities);
    navigate("/cartpage");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const increaseQuantity = () => {
    setQuantities(quantities + 1);
  };
  const decreaseQuantity = () => {
    if (quantities > 1) {
      setQuantities(quantities - 1);
    }
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="main-container">
      <Header />
      <div
        className="detail_top_box"
        style={{
          backgroundImage: `linear-gradient(180deg, hsla(0, 0%, 0%, 20%) 0%, hsla(0, 0%, 0%, 80%) 100%), url(${product.images[0]})`,
        }}
      ></div>
      <div className="detail_shoes_box">
        <div className="detail_shoes_box_inner">
          <div className="shoes_main_flex">
            <div className="shoes_title">
              <nav>
                <ol class="awb-breadcrumb-list">
                  <li class="fusion-breadcrumb-item awb-breadcrumb-sep">
                    <Link to="/" class="fusion-breadcrumb-link">
                      <span>Home</span>
                    </Link>
                  </li>
                  <li class="fusion-breadcrumb-item awb-breadcrumb-sep">
                    <Link to="/shop" class="fusion-breadcrumb-link">
                      <span>Rain Boots</span>
                    </Link>
                  </li>
                  <li class="fusion-breadcrumb-item" aria-current="page">
                    <span class="breadcrumb-leaf">{product.name}</span>
                  </li>
                </ol>
              </nav>
            </div>

            <div className="shoes_img">
              <img alt="" src={product.images[0]} />
            </div>

            <div className="shoes_details">
              <h2>{product.name}</h2>
              <p className="shoes_details_review">
                Be the first to leave a review.
              </p>
              <p className="shoes_details_price">
                <bdi>
                  <span class="woocommerce-Price-currencySymbol">$</span>
                  {product.price}
                </bdi>
              </p>
              <button className="shoes_ship">Worldwide Shipping</button>
              <div className="shoes_des">
                {product.description.map((des, index) => (
                  <p key={index}>{des}</p>
                ))}
              </div>
            </div>

            <div className="shoes_qty_add">
              <div className="qty_add_box">
                <div class="quantity_buttons_added">
                  <input
                    type="button"
                    value="-"
                    class="minus"
                    onClick={decreaseQuantity}
                  />

                  <input
                    type="number"
                    id="quantity_673c411bd98a5"
                    class="input-text qty text"
                    name="quantity"
                    value={quantities}
                    aria-label="Product quantity"
                    size="4"
                    min="1"
                    max=""
                    step="1"
                    placeholder=""
                    inputmode="numeric"
                    autocomplete="off"
                  />
                  <input
                    type="button"
                    value="+"
                    class="plus"
                    onClick={increaseQuantity}
                  />
                </div>
                <button
                  type="submit"
                  name="add-to-cart"
                  value="2673"
                  class="single_add_to_cart_button "
                  onClick={() => handleAddToCart()}
                >
                  Add to cart
                </button>
              </div>

              <div class="fusion-separator-fusion-full-width-sep">
                <div class="fusion-separator-border-sep-single "></div>
              </div>

              <div className="shoes_share">SHARE</div>
              <div className="shoes_share_imgs">
                <img alt="" src="/head/share.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
