import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import "../css/checkout.css";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import { CountryDropdown } from "react-country-region-selector";
import { useNavigate, Link } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCartShopping } from "@fortawesome/free-solid-svg-icons";

const CheckOut = () => {
  const { cartItems, clearCart } = useContext(CartContext);
  const [selectedCountry, setSelectedCountry] = useState("US");

  const handleCountryChange = (val) => {
    setSelectedCountry(val);
  };
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [town, setTown] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [apartment, setApatrment] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handlePlaceOrder = () => {
    const newErrors = {};

    if (!firstName)
      newErrors.firstName =
        "Please enter correct details for this required field.";
    if (!lastName)
      newErrors.lastName =
        "Please enter correct details for this required field.";
    if (!companyName)
      newErrors.companyName =
        "Please enter correct details for this required field.";
    if (!town)
      newErrors.town = "Please enter correct details for this required field.";
    if (!address)
      newErrors.address =
        "Please enter correct details for this required field.";
    if (!zip)
      newErrors.zip = "Please enter correct details for this required field.";
    if (!phone)
      newErrors.phone = "Please enter correct details for this required field.";
    if (!email)
      newErrors.email = "Please enter correct details for this required field.";

    if (!selectedCountry)
      newErrors.selectedCountry =
        "Please enter correct details for this required field.";
    if (Object.keys(newErrors).length > 0) {
      alert("Please fill all  fields");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      alert("Order placed!");
      clearCart();
      navigate("/paysuccess");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Header />
      <div
        className="detail_top_box"
        style={{
          background: "#333333",
        }}
      ></div>
      <div className="check_box">
        <div className="check_box_inner">
          <div class="avada-myaccount-user">
            <div class="avada-myaccount-user-column username">
              <FontAwesomeIcon icon={faCheck} />
              <span class="hello">Hello</span>
            </div>

            <div class="avada-myaccount-user-column">
              <span class="view-cart">
                <Link to="/cartpage">
                  <FontAwesomeIcon icon={faCartShopping} />
                  View Cart
                </Link>
              </span>
            </div>
          </div>

          <div className="check_main_div">
            <form>
              <div className="customer_details">
                <div>
                  <h3>Billing details</h3>
                  <div className="check_info">
                    <p
                      class="form-row form-row-first validate-required"
                      id="billing_first_name_field"
                      data-priority="10"
                    >
                      <label for="billing_first_name" class="">
                        First name&nbsp;
                        <abbr class="required" title="required">
                          *
                        </abbr>
                      </label>
                      <span class="woocommerce-input-wrapper">
                        <input
                          type="text"
                          class="input-text "
                          name="billing_first_name"
                          id="billing_first_name"
                          placeholder=""
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          autocomplete="given-name"
                          style={{
                            borderLeft: errors.firstName
                              ? "4px solid red"
                              : "1px solid #d2d2d2",
                          }}
                        />
                      </span>
                      {errors.firstName && (
                        <p className="error">{errors.firstName}</p>
                      )}
                    </p>
                    <p
                      class="form-row form-row-last validate-required"
                      id="billing_last_name_field"
                      data-priority="20"
                    >
                      <label for="billing_last_name" class="">
                        Last name&nbsp;
                        <abbr class="required" title="required">
                          *
                        </abbr>
                      </label>
                      <span class="woocommerce-input-wrapper">
                        <input
                          type="text"
                          class="input-text "
                          name="billing_last_name"
                          id="billing_last_name"
                          placeholder=""
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          autocomplete="family-name"
                          style={{
                            borderLeft: errors.lastName
                              ? "4px solid red"
                              : "1px solid #d2d2d2",
                          }}
                        />
                      </span>
                      {errors.lastName && (
                        <p className="error">{errors.lastName}</p>
                      )}
                    </p>
                    <p
                      class="form-row form-row-wide"
                      id="billing_company_field"
                      data-priority="30"
                    >
                      <label for="billing_company" class="">
                        Company name&nbsp;
                        <span class="optional">(optional)</span>
                      </label>
                      <span class="woocommerce-input-wrapper">
                        <input
                          type="text"
                          class="input-text "
                          name="billing_company"
                          id="billing_company"
                          placeholder=""
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          autocomplete="organization"
                          style={{
                            borderLeft: errors.companyName
                              ? "4px solid red"
                              : "1px solid #d2d2d2",
                          }}
                        />
                      </span>
                      {errors.companyName && (
                        <p className="error">{errors.companyName}</p>
                      )}
                    </p>
                    <p
                      class="form-row form-row-wide address-field update_totals_on_change validate-required"
                      id="billing_country_field"
                      data-priority="40"
                    >
                      <label for="billing_country" class="">
                        Country / Region&nbsp;
                        <abbr class="required" title="required">
                          *
                        </abbr>
                      </label>
                      <span class="woocommerce-input-wrapper">
                        <CountryDropdown
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          classes="country-select" // 可自定义类名
                        />
                      </span>
                      {errors.selectedCountry && (
                        <p className="error">{errors.selectedCountry}</p>
                      )}
                    </p>
                    <p
                      class="form-row address-field validate-required form-row-wide"
                      id="billing_address_1_field"
                      data-priority="50"
                    >
                      <label for="billing_address_1" class="">
                        Street address&nbsp;
                        <abbr class="required" title="required">
                          *
                        </abbr>
                      </label>
                      <span class="woocommerce-input-wrapper">
                        <input
                          type="text"
                          class="input-text "
                          name="billing_address_1"
                          id="billing_address_1"
                          placeholder="House number and street name"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          autocomplete="address-line1"
                          data-placeholder="House number and street name"
                          style={{
                            borderLeft: errors.address
                              ? "4px solid red"
                              : "1px solid #d2d2d2",
                          }}
                        />
                      </span>
                      {errors.address && (
                        <p className="error">{errors.address}</p>
                      )}
                    </p>
                    <p
                      class="form-row address-field form-row-wide"
                      id="billing_address_2_field"
                      data-priority="60"
                    >
                      <label for="billing_address_2" class="screen-reader-text">
                        Apartment, suite, unit, etc.&nbsp;
                        <span class="optional">(optional)</span>
                      </label>
                      <span class="woocommerce-input-wrapper">
                        <input
                          type="text"
                          class="input-text "
                          name="billing_address_2"
                          id="billing_address_2"
                          placeholder="Apartment, suite, unit, etc. (optional)"
                          value={apartment}
                          onChange={(e) => setApatrment(e.target.value)}
                          autocomplete="address-line2"
                          data-placeholder="Apartment, suite, unit, etc. (optional)"
                        />
                      </span>
                    </p>
                    <p
                      class="form-row address-field validate-required form-row-wide"
                      id="billing_city_field"
                      data-priority="70"
                      data-o_class="form-row form-row-wide address-field validate-required"
                    >
                      <label for="billing_city" class="">
                        Town / City&nbsp;
                        <abbr class="required" title="required">
                          *
                        </abbr>
                      </label>
                      <span class="woocommerce-input-wrapper">
                        <input
                          type="text"
                          class="input-text "
                          name="billing_city"
                          id="billing_city"
                          placeholder=""
                          value={town}
                          onChange={(e) => setTown(e.target.value)}
                          autocomplete="address-level2"
                          style={{
                            borderLeft: errors.town
                              ? "4px solid red"
                              : "1px solid #d2d2d2",
                          }}
                        />
                      </span>
                      {errors.town && <p className="error">{errors.town}</p>}
                    </p>
                    <p
                      class="form-row address-field validate-required validate-postcode form-row-wide"
                      id="billing_postcode_field"
                      data-priority="90"
                      data-o_class="form-row form-row-wide address-field validate-required validate-postcode"
                    >
                      <label for="billing_postcode" class="">
                        ZIP Code&nbsp;
                        <abbr class="required" title="required">
                          *
                        </abbr>
                      </label>
                      <span class="woocommerce-input-wrapper">
                        <input
                          type="text"
                          class="input-text "
                          name="billing_postcode"
                          id="billing_postcode"
                          placeholder=""
                          value={zip}
                          onChange={(e) => setZip(e.target.value)}
                          autocomplete="postal-code"
                          style={{
                            borderLeft: errors.zip
                              ? "4px solid red"
                              : "1px solid #d2d2d2",
                          }}
                        />
                      </span>
                      {errors.zip && <p className="error">{errors.zip}</p>}
                    </p>
                    <p
                      class="form-row form-row-wide validate-required validate-phone"
                      id="billing_phone_field"
                      data-priority="100"
                    >
                      <label for="billing_phone" class="">
                        Phone&nbsp;
                        <abbr class="required" title="required">
                          *
                        </abbr>
                      </label>
                      <span class="woocommerce-input-wrapper">
                        <input
                          type="tel"
                          class="input-text "
                          name="billing_phone"
                          id="billing_phone"
                          placeholder=""
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          autocomplete="tel"
                          style={{
                            borderLeft: errors.phone
                              ? "4px solid red"
                              : "1px solid #d2d2d2",
                          }}
                        />
                      </span>
                      {errors.phone && <p className="error">{errors.phone}</p>}
                    </p>
                    <p
                      class="form-row form-row-wide validate-required validate-email"
                      id="billing_email_field"
                      data-priority="110"
                    >
                      <label for="billing_email" class="">
                        Email address&nbsp;
                        <abbr class="required" title="required">
                          *
                        </abbr>
                      </label>
                      <span class="woocommerce-input-wrapper">
                        <input
                          type="email"
                          class="input-text "
                          name="billing_email"
                          id="billing_email"
                          placeholder=""
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          autocomplete="email username"
                          style={{
                            borderLeft: errors.email
                              ? "4px solid red"
                              : "1px solid #d2d2d2",
                          }}
                        />
                      </span>
                      {errors.email && <p className="error">{errors.email}</p>}
                    </p>
                  </div>
                  <a
                    data-name="col-2"
                    href="#"
                    class="fusion-button button-default fusion-button-default-size button continue-checkout"
                    onClick={handlePlaceOrder}
                  >
                    Continue{" "}
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckOut;
