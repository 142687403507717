import React, { useState } from "react";
import Footer from "./Footer";
import "../css/contact.css";
import Header from "./Header";

function Contact() {
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    msg: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (!formData.name) errors.name = " ";
    if (!formData.email) errors.email = " ";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    alert("Send Successful!");
    setFormData({
      name: "",
      email: "",
      subject: "",
      msg: "",
    });
    setFormErrors({});
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div
        className="detail_top_box"
        style={{
          background: "#333333",
        }}
      ></div>

      <div className="contact_box">
        <div className="contact_box_inner">
          <div className="contact_left">
            <div className="contact_left_inner">
              <form>
                <div
                  class="fusion-form-field fusion-form-text-field fusion-form-label-above"
                  data-form-id="2473"
                >
                  <div class="fusion-form-label-wrapper">
                    <label for="name">
                      Your Name{" "}
                      <abbr
                        class="fusion-form-element-required"
                        title="required"
                      >
                        *
                      </abbr>
                    </label>
                  </div>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    class="fusion-form-input"
                    required="true"
                    aria-required="true"
                    data-holds-private-data="false"
                    minlength="0"
                    style={{
                      border: formErrors.name
                        ? "2px solid #e0284f"
                        : "1px solid hsla(0, 0%, calc(0% + 86%), 100%)",
                      boxShadow: formErrors.name
                        ? "0 0 1.5px 1px rgba(224,40,79,.65)"
                        : "none",
                    }}
                  />
                </div>
                <div
                  class="fusion-form-field fusion-form-email-field fusion-form-label-above"
                  data-form-id="2473"
                >
                  <div class="fusion-form-label-wrapper">
                    <label for="email_address">
                      Your Email{" "}
                      <abbr
                        class="fusion-form-element-required"
                        title="required"
                      >
                        *
                      </abbr>
                    </label>
                  </div>
                  <input
                    type="email"
                    name="email_address"
                    id="email_address"
                    value={formData.email}
                    onChange={handleChange}
                    class="fusion-form-input"
                    required="true"
                    aria-required="true"
                    data-holds-private-data="false"
                    style={{
                      border: formErrors.email
                        ? "2px solid #e0284f"
                        : "1px solid hsla(0, 0%, calc(0% + 86%), 100%)",
                      boxShadow: formErrors.email
                        ? "0 0 1.5px 1px rgba(224,40,79,.65)"
                        : "none",
                    }}
                  />
                </div>
                <div
                  class="fusion-form-field fusion-form-text-field fusion-form-label-above"
                  data-form-id="2473"
                >
                  <div class="fusion-form-label-wrapper">
                    <label for="subject">Subject</label>
                  </div>
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    class="fusion-form-input"
                    data-holds-private-data="false"
                    minlength="0"
                  />
                </div>
                <div
                  class="fusion-form-field fusion-form-textarea-field fusion-form-label-above"
                  data-form-id="2473"
                >
                  <label for="message">
                    Your Message{" "}
                    <abbr class="fusion-form-element-required" title="required">
                      *
                    </abbr>
                  </label>
                  <textarea
                    cols="40"
                    minlength="0"
                    rows="4"
                    tabindex=""
                    id="message"
                    name="message"
                    class="fusion-form-input"
                    required="true"
                    aria-required="true"
                    data-holds-private-data="false"
                    style={{
                      height: "112px",
                      border: formErrors.name
                        ? "2px solid #e0284f"
                        : "1px solid hsla(0, 0%, calc(0% + 86%), 100%)",
                      boxShadow: formErrors.name
                        ? "0 0 1.5px 1px rgba(224,40,79,.65)"
                        : "none",
                    }}
                    value={formData.msg}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div
                  class="fusion-form-field fusion-form-submit-field fusion-form-label-above"
                  data-form-id="2473"
                >
                  <button
                    type="submit"
                    className="contact_btn"
                    data-form-number="2473"
                    tabindex=""
                    onClick={handleFormSubmit}
                  >
                    <span class="fusion-button-text">Submit</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="contact_right">
            <div className="contact_right_inner">
              <h2>Contact Us</h2>
              <div class="fusion-text-fusion-text-1">
                <p>Address：</p>
                <p>
                  <b>
                    Room K-916, 9th Floor, Building 1, Wancaicheng, No. 127,
                    Longquan Road, Lianhua Street, Wuhua District, Kunming City,
                    Yunnan Province, China
                  </b>
                </p>
              </div>
              <div class="contact_right_hr">
                <div class="contact_right_hr_inner"></div>
              </div>
              <h2 class="contact_phone_inner">Phone Number</h2>
              <p className="contact_phone_b">
                <b>Phone/Whatsapp: 2136496328</b>
              </p>
              <div class="contact_right_hr">
                <div class="contact_right_hr_inner"></div>
              </div>{" "}
              <h2 class="contact_phone_inner">Email Address</h2>
              <p className="contact_phone_b">
                <b>work@woksi.cfd</b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
